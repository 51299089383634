<template>
  <div class="content">
    <div class="List-title">
      <div class="titleLine">
        <span style="width:2rem"></span>
        <span :class="open=='01'?'blueColor':''" @click="setOpen('01')">美图欣赏</span>
        <span style="width:0.96rem"></span>
        <span :class="open=='02'?'blueColor':''" @click="setOpen('02')">视频欣赏</span>
        <span style="width:2rem"></span>
      </div>
    </div>
    <div style="height:1.6rem"></div>
    <van-list
      v-if="open=='01'"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div class="tabArea" v-for="item in dataList" :key="item.name">
        <img class="imageArea" :src="item.url" />
        <div class="tabBottomArea">
          <div class="tabBottomLeft">{{item.name}}</div>
          <div class="tabBottomRight">
            <!-- <img src="./imgs/zan.png" style="width:0.627rem;height:0.427rem;" />
            <div>{{item.zan}}</div>-->
          </div>
        </div>
      </div>
    </van-list>
    <van-list
      v-if="open=='02'"
      v-model="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
    >
      <div @click="playOrParse" class="tabArea" v-for="(item,index) in dataList" :key="item.name">
        <video class="imageArea" :id="'video'+index" :data-index="index" controls>
          <source :src="item.url" type="video/mp4" />您的浏览器不支持 HTML5 video 标签。
        </video>
        <div class="tabBottomArea">
          <div class="tabBottomLeft">{{item.name}}</div>
          <div class="tabBottomRight">
            <!-- <img src="./imgs/zan.png" style="width:0.627rem;height:0.427rem;" />
            <div>{{item.zan}}</div>-->
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { Notify, List } from 'vant';
export default {
  name: 'FineView',
  components: {
    [List.name]: List
  },
  data() {
    return {
      open: '01',
      dataList: [],
      loading: false,
      finished: false,
      page: 0,
      size: 10
    };
  },
  watch: {},
  created() {
    // this.getRecords();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const that = this;
      let dataList = that.dataList;
      let page = that.page;
      let size = that.size;
      that.loading = true;
      let finished = that.finished;
      const open = that.open;
      const topicSymbol = that.getTopicSymbol(open);
      page += 1;
      that.$api
        .getContentByPage({ page: page, size: size, topicSymbol: topicSymbol })
        .then(res => {
          if (res.data.code === 10000) {
            const data = res.data.data.records;
            const tempList = [];
            data.forEach(item => {
              tempList.push(that.getIntroduceObj(item));
            });
            dataList = dataList.concat(tempList);
            if (dataList.length < res.data.data.total) {
              finished = false;
            } else {
              finished = true;
            }
            that.page = page;
            that.dataList = dataList;
            that.finished = finished;
            that.loading = false;
            console.log(that.dataList);
          } else {
            Notify({ type: 'warning', message: res.data.msg });
          }
        });
    },
    setOpen(num) {
      this.open = num;
      this.page = 0;
      this.dataList = [];
      this.finished = false;
      this.getRecords();
    },
    onLoad() {
      console.log('123');
      this.getRecords();
    },
    getIntroduceObj(obj) {
      const open = this.open;
      const symbol = {};
      switch (open) {
        case '01':
          symbol.name = obj.content.sceneryName;
          symbol.url = obj.content.sceneryPic;
          symbol.id = obj.id;
          symbol.zan = obj.praiseCount;
          symbol.iszan = false;
          symbol.play = false;
          break;
        case '02':
          symbol.name = obj.content.sceneryName;
          symbol.url = obj.content.sceneryVideo ? obj.content.sceneryVideo : '';
          symbol.id = obj.id;
          symbol.zan = obj.praiseCount;
          symbol.iszan = false;
          symbol.play = false;
          break;
        default:
          symbol.name = obj.content.sceneryName;
          symbol.url = obj.content.sceneryPic;
          symbol.id = obj.id;
          symbol.zan = obj.praiseCount;
          symbol.iszan = false;
          symbol.play = false;
      }
      return symbol;
    },
    getTopicSymbol(open) {
      let symbol = '';
      switch (open) {
        case '01':
          symbol = 'h5-picture-scenery';
          break;
        case '02':
          symbol = 'h5-video-scenery ';
          break;
        default:
          symbol = 'h5-picture-scenery';
      }
      return symbol;
    },
    // 视频播放或暂停
    playOrParse(e) {
      console.log(e);
    }
  }
};
</script>
<style scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
}

.List-title {
  width: 100%;
  height: 1.12rem;
  border-bottom: 0.01rem solid #c7c7c7;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
  background: #fff;
}

.List-title span {
  width: 2.52rem;
  height: 1.12rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  line-height: 1.12rem;
  float: left;
  text-align: center;
}
.blueColor {
  color: #00a5ff !important;
  box-sizing: border-box;
  border-bottom: #00a5ff 0.06rem solid;
}
.titleLine {
  height: 100%;
  width: 100%;
  position: relative;
}
.tabArea {
  margin: 0.4rem 0.4rem 0 0.4rem;
  height: 4.8rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.027rem 0.16rem 0rem rgba(0, 0, 0, 0.14);
  border-radius: 0.213rem;
  position: relative;
  overflow: hidden;
}

.imageArea {
  width: 100%;
  height: 100%;
  border-radius: 0.213rem;
}

.tabBottomArea {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  width: 100%;
  height: 0.96rem;
  background: rgba(255, 255, 255, 0.81);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tabBottomLeft {
  font-size: 0.347rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  margin-left: 0.267rem;
}

.tabBottomRight {
  font-size: 0.293rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 1);
  margin-right: 0.267rem;
  height: 100%;
  display: flex;
  align-items: center;
}
</style>



